import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
//import liff from '@line/liff'
import i18n from './i18n'
import liff from './assets/liff'

//liff.init({liffId:'1654385793-rzPgpz22'})

Vue.config.productionTip = false

Vue.prototype.$liff = liff

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');