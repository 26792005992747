import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    phone: '',
    egg: '',
    egg_cost: null,
    prepay_type: '',
    prepay_name: '',
    prepay_cost: null,
    monthly_cost: null,
    monthly_name: '',
    service_fee_discount: 3000,
    service_fee: 1000,
    monthly_plan: {
      '통신사 모름': [
        /*첫행은 레이블 */
        [29999, 17000, 24200, 25000, 28050, 28500, 29700, 28800, 10900, 21500],
        [36300, 36300, 36000, 30000, 33000, 35000, 37500, 38000, 39000, 39600, 39900, 31900],
        [40000, 40000, 40700, 44000, 45000, 49900],
        [50000, 64800, 50600, 52900, 55000, 56100, 58500, 59000, 59900, 63300, 65100, 66000]
      ],
      'Smartel': [
        [29999, 
          ['Smartel-LG (￦24,200) 300MB + 400kbps', 24200, '음성 300분, 문자 300건', '데이터 : 300MB + 400kbps 속도제한'],
          ['Smartel-LG (￦29,700) 300MB + 400kbps', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps']
        ],
        [30000, 
          ['Smartel-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Smartel ￦33,000', 33000, 'SKT - 음성/문자 : Unlimited, 300MB', 'LG - 음성/문자 : 100/100, 10GB'],
          ['Smartel-SK (￦36,000) 300MB+', 36000, '음성/문자 : 무제한', '데이터 : 300MB + 3Mbps 속도제한'],
          ['Smartel ￦39,000', 39000, 'SKT - 음성/문자 : 100/100, 15GB + 3Mbps', 'LG - 음성/문자 : 100/100, 15GB + 3Mbps'],
        ],
        [40000, 
          ['Smartel-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '국제전화 : 일 20분 제공', '데이터 : 300MB + 3Mbps'],
          ['Smartel-SK (￦45,000) 2.2GB', 45000, '국내 음성&문자 : 무제한', '데이터 : 2.2GB'],
          ['Smartel-SK (￦49,900) 3.5GB', 49900, '국내 음성&문자 : 무제한', '데이터 : 3.5GB'],
        ],
        [50000, 
          ['Smartel-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['Smartel ￦55,000', 55000, 'SKT - 음성/문자 : Unlimited', 'LG - 음성/문자 : Unlimited, 11GB + 3Mbps / 11GB+2GB(day)+3Mbps'],
          ['Smartel-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Smartel-SK (￦59,900) 11GB+', 59900, '음성 + 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps']
        ]
      ],
      'Eyagi': [
        [29999,
          ['Eyagi-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps'],
        ],
        [30000,
          ['Eyagi-SK (￦36,000) 300MB+', 36000, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Eyagi ￦36,000', 36300, 'KT - 음성/문자 : Unlimited, 300MB + 3Mbps', 'LG - 음성/문자 : Unlimited, 300MB + 3Mbps'],
          ['Eyagi ￦33,000', 33000, 'SKT - 음성/문자 : Unlimited, 300MB', 'KT - 음성/문자 : 100/100, 10GB'],
          ['Eyagi-SK (￦39,000) 15GB+', 39000, '음성: 100분, 문자: 100건', '데이터 : 15GB + 3Mbps'],
          ['Eyagi-KT (￦39,600) 15GB+', 39600, '음성: 100분, 문자: 100건', '데이터 : 15GB + 3Mbps'],
        ],
        [40000,
          ['Eyagi-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 300MB + 3Mbps'],
          ['Eyagi-SK (￦49,900) 3.5GB', 49900, '음성 + 문자 : 무제한', '데이터 : 3.5GB'],
        ],
        [50000,
          ['Eyagi-SK (￦59,900) 11GB+', 59900, '음성 + 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Eyagi-LG (￦50,600) 11GB+', 50600, '국내 음성 & 문자 : 무제한', '데이터 : 11GB + 3Mbps 속도제한'],
          ['Eyagi-KT (￦58,500) 10GB+', 58500, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
          ['Eyagi-SK (￦66,000) 100GB+', 66000, '음성 + 문자 : 무제한', '데이터 : 100GB + 5Mbps'],
          ['Eyagi ￦55,000', 55000, 'SKT - 음성/문자 : Unlimited, 6.5GB', 'LG - 음성/문자 : Unlimited, 11GB + 3Mbps'],
          ['Eyagi-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps 속도제한'],
        ]
      ],
      'U+mobile': [
        [29999,
          ['U+mobile-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps'],
          ['U+mobile-LG (￦24,200) 300MB+', 24200, '음성: 300분, 문자: 300건', '데이터 : 300MB + 400Kbps']
        ],
        [30000,
          ['U+mobile-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['U+mobile-LG (￦33,000) 10GB', 33000, '음성: 100분, 문자: 100건', '데이터 : 10GB'],
          ['U+mobile-LG (￦39,000) 15GB+', 39000, '음성: 100분, 문자: 100건', '데이터 : 15GB + 3Mbps']
        ],
        [40000,
          ['U+mobile-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['U+mobile-LG (￦44,000) 3.5GB', 44000, '국내 음성 & 문자 : 무제한', '데이터 : 3GB + 3Mbps 속도제한'],
        ],
        [50000,
          ['U+mobile-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['U+mobile-LG (￦55,000) 11GB+', 55000, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['U+mobile-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
        ]
      ],
      'Myworld': [
        [29999,
          ['Myworld-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps'],
          ['Myworld-LG (￦24,200) 300MB+', 24200, '음성: 300분, 문자: 300건', '데이터 : 300MB + 400Kbps']
        ],
        [30000,
          ['Myworld ￦36,300', 36300, 'KT - 음성/문자 : Unlimited, 300MB + 3Mbps', 'LG - 음성/문자 : Unlimited, 300MB + 3Mbps'],
          ['Myworld-LG (￦33,000) 10GB', 33000, '음성: 100분, 문자: 100건', '데이터 : 10GB'],
          ['Myworld ￦39,000', 39000, 'KT - 음성/문자 : Unlimited, 15GB + 3Mbps', 'LG - 음성/문자 : Unlimited, 15GB + 3Mbps']
        ],
        [40000,
          ['Myworld-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한','국제전화: 일 20분 제공', '데이터 : 300MB + 3Mbps'],
        ],
        [50000,
          ['Myworld-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['Myworld-LG (￦55,000) 11GB+', 55000, '음성/문자 : Unlimited, 11GB + 3Mbps / 11GB+2GB(day)+3Mbps'],
          ['Myworld-KT (￦58,500) 10GB+', 58500, '음성 & 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
          ['Myworld-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Myworld-LG (￦66,000) 5GB+', 66000, '음성 & 문자 : 무제한', '데이터 : 매일 5GB + 5Mbps'],
        ]
      ],
      'Eyes': [
        [29999,
          ['Eyes-LG (￦24,200) 300MB+', 24200, '음성: 300분, 문자: 300건', '데이터 : 300MB + 400Kbps'],
          ['Eyes-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps']
        ],
        [30000,
          ['Eyes-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Eyes-KT (￦30,000) 10GB/15GB', 30000, '음성: 100분/185분, 문자: 100건', '데이터 : 10GB/15GB'],
          ['Eyes-SK (￦33,000) 300MB', 33000, '음성 + 문자 : 무제한', '데이터 : 300MB'],
          ['Eyes-KT (￦35,000) 3GB/10GB+', 35000, '음성 + 문자 : 무제한/100분', '데이터 : 3GB/10GB + 400Kbps'],
          ['Eyes ￦39,000', 39000, 'SKT - 음성/문자 : 100/100, 15GB + 3Mbps', 'LG - 음성/문자 : 100/100, 15GB + 3Mbps']
        ],
        [40000,
          ['Eyes-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한','국제전화: 일 20분 제공', '데이터 : 300MB + 3Mbps 속도제한'],
          ['Eyes ￦45,000', 45000, 'SKT - 음성/문자 : Unlimited, 2.2GB', 'LG - 음성/문자 : Unlimited, 6GB'],
          ['Eyes-SK (￦49,900) 300MB+', 49900, '음성 + 문자 : 무제한', '데이터 : 3.5GB']
        ],
        [50000,
          ['Eyes-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['Eyes ￦55,000', 55000, 'SKT - 음성/문자 : Unlimited, 6.5GB', 'KT - 음성/문자 : Unlimited, 10GB + 2GB(day) + 3Mbps', 'LG - 음성/문자 : Unlimited, 11G + 3Mbps'],
          ['Eyes-SK (￦59,900) 11GB+', 59900, '음성 & 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Eyes-SK (￦66,000) 100GB+', 66000, '음성 + 문자 : 무제한', '데이터 : 100GB + 5Mbps'],
        ]
      ],
      'FreeT': [
        [29999,
          ['FreeT-LG (￦24,200) 300MB+', 24200, '음성: 300분, 문자: 300건', '데이터 : 300MB + 400Kbps'],
          ['FreeT-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps']
        ],
        [30000,
          ['FreeT-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['FreeT ￦33,000', 33000, 'SKT - 음성/문자: Unlimited, 300MB', 'KT - 음성/문자: Unlimited, 300MB', 'KT - 음성/문자: 350/350, 6GB', 'LG - 음성/문자: 100/100, 10GB'],
          ['FreeT-KT (￦35,000) 3GB', 35000, '음성 + 문자 : 무제한', '데이터 : 3GB'],
          ['FreeT ￦36,000', 36000, 'SKT - 음성/문자: Unlimited, 300MB + 3Mbps', 'KT - 음성/문자: Unlimited, 300MB + 3Mbps / 2.5GB + 400Kbps'],
          ['FreeT ￦39,000', 39000, 'SKT - 음성/문자: 100/100, 15GB + 3Mbps', 'LG - 음성/문자: 100/100, 15GB + 3Mbps'],
          ['FreeT-KT (￦39,600) 15GB', 39600, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps'],
        ],
        [40000,
          ['FreeT-KT (￦40,000) 3GB+', 40000, '음성 + 문자 : 무제한', '데이터 : 3GB + 1Mbps'],
          ['FreeT-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 300MB + 3Mbps 속도제한'],
          ['FreeT-SK (￦45,000) 2.2GB+', 45000, '음성 + 문자 : 무제한', '데이터 : 2.2GB']
        ],
        [50000,
          ['FreeT-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['FreeT ￦55,000', 55000, 'SKT - 음성/문자 : Unlimited, 6.5GB', 'LG - 음성/문자 : Unlimited, 11GB + 3Mbps'],
          ['FreeT-KT (￦58,500) 10GB+', 58500, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
          ['FreeT-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['FreeT-SK (￦59,900) 11GB+', 59900, '음성 & 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['FreeT ￦66,000', 66000, 'SKT - 음성/문자: Unlimited, 100GB + 5Mbps', 'KT - 음성/문자: Unlimited, 100GB + 5Mbps', 'LG - 음성/문자: Unlimited, 5GB(day) + 5Mbps'],
        ]
      ],
      'Mobing': [
        [29999,
          ['Mobing-KT (￦28,500) 300MB+', 28500, '음성 + 문자 : 무제한', '데이터 : 300MB'],
          ['Mobing ￦29,700', 29700, 'KT - 음성/문자: 100/100, 10GB', 'LG - 음성/문자: Unlimited, 300MB + 1Mbps']
        ],
        [30000,
          ['Mobing-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Mobing ￦33,000', 33000, 'SKT - 음성/문자: Unlimited, 300MB', 'KT - 음성/문자: 100/100, 10GB', 'LG - 음성/문자: 100/100, 10GB'],
          ['Mobing ￦36,000', 36000, 'SKT - 음성/문자: Unlimited, 300MB + 3Mbps', 'KT - 음성/문자: Unlimited, 300MB + 3Mbps'],
          ['Mobing ￦39,000', 39000, 'SKT - 음성/문자: 100/100, 15GB + 3Mbps', 'KT - 음성/문자: 100/100, 15GB + 3Mbps', 'LG - 음성/문자: 100/100, 15GB + 3Mbps'],
        ],
        [40000,
          ['Mobing-LG (￦44,000) 3GB+', 44000, '음성 + 문자 : 무제한', '데이터 : 3GB + 3Mbps 속도제한'],
          ['Mobing-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 300MB + 3Mbps'],
          ['Mobing-SK (￦45,000) 2.2GB+', 45000, '음성 + 문자 : 무제한', '데이터 : 2.2GB'],
          ['Mobing-SK (￦49,900) 3.5GB+', 49900, '음성 + 문자 : 무제한', '데이터 : 3.5GB']
        ],
        [50000,
          ['Mobing-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['Mobing ￦55,000', 55000, 'SKT - 음성/문자 : Unlimited, 6.5GB', 'LG - 음성/문자 : Unlimited, 11GB + 3Mbps'],
          ['Mobing-KT (￦58,500) 10GB+', 58500, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
          ['Mobing-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Mobing-SK (￦59,900) 11GB+', 59900, '음성 & 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Mobing ￦66,000', 66000, 'SKT - 음성/문자: Unlimited, 100GB + 5Mbps', 'KT - 음성/문자: Unlimited, 100GB + 5Mbps', 'LG - 음성/문자: Unlimited, 5GB(day) + 5Mbps'],
        ]
      ],
      'Snowman': [
        [29999,
          ['Snowman-KT (￦29,700) 1GB+', 29700, '음성(KT) + 문자 : 무제한', '데이터 : 1GB + 400Kbps'],
        ],
        [30000,
          ['Snowman-KT (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Snowman-KT (￦39,600) 15GB+', 39600, '음성 100분, 문자 : 100건', '데이터 : 15GB + 3Mbps']
        ],
        [50000,
          ['Snowman-KT (￦58,500) 10GB+', 58500, '음성 & 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps']
        ]
      ],
      'Code Mobile(EG)': [
        [30000,
          ['Code Mobile ￦36,300', 36300, 'KT - 음성/문자: Unlimited, 300MB + 3Mbps', 'LG - 음성/문자: Unlimited, 300MB + 3Mbps'],
          ['Code Mobile-KT (￦37,500) 10GB+', 37500, '음성 100분, 문자 : 100건', '데이터 : 10GB + 400Kbps'],
          ['Code Mobile-KT (￦39,600) 15GB+', 39600, '음성 100분, 문자 : 100건', '데이터 : 15GB + 3Mbps'],
          ['Code Mobile-KT (￦33,000) 10GB', 33000, '음성 100분, 문자 : 100건', '데이터 : 10GB']
        ],
        [50000,
          ['Code Mobile-KT (￦58,500) 10GB+', 58500, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
          ['Code Mobile-KT (￦64,800) 100GB+', 64800, '음성 + 문자 : 무제한', '데이터 : 100GB + 5Mbps']
        ]
      ],
      '7mobile': [
        [30000,
          ['7Mobile-SK (￦36,000) 300MB+', 36000, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['7Mobile-SK (￦39,000) 15GB+', 39000, '음성 100분, 문자 : 100건', '데이터 : 15GB + 3Mbps'],
          ['7Mobile-SK (￦33,000) 300MB', 33000, '음성 + 문자 : 무제한', '데이터 : 300MB'],
        ],
        [40000,
          ['7Mobile-SK (￦45,000) 2.2GB', 45000, '음성 + 문자 : 무제한', '데이터 : 2.2GB'],
          ['7Mobile-SK (￦49,900) 3.5GB', 49900, '음성 + 문자 : 무제한', '데이터 : 3.5GB']
        ],
        [50000,
          ['7Mobile-SK (￦59,900) 11GB+', 59900, '음성 + 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['7Mobile-SK (￦66,000) 100GB+', 66000, '음성 + 문자 : 무제한', '데이터 : 100GB + 5Mbps'],
          ['7Mobile-SK (￦55,000) 6.5GB+', 55000, '국내 음성 + 문자 : 무제한', '데이터 : 6.5GB']
        ]
      ],
      'Joytel': [
        [29999,
          ['Joytel-LG (￦24,200) 300MB+', 24200, '음성 300분, 문자 300건', '데이터 : 300MB + 400Kbps'],
          ['Joytel-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps']
        ],
        [30000,
          ['Joytel-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Joytel-LG (￦33,000) 10GB', 33000, '음성 100분, 문자 100건', '데이터 : 10GB'],
          ['Joytel-LG (￦39,000) 15GB+', 39000, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps']
        ],
        [40000,
          ['Joytel-LG (￦44,000) 3GB+', 44000, '음성 + 문자 : 무제한', '데이터 : 3GB + 3Mbps']
        ],
        [50000,
          ['Joytel-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['Joytel-LG (￦55,000) 11GB+', 55000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 3Mbps'],
          ['Joytel-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Joytel-LG (￦66,000) 5GB+', 66000, '음성 & 문자 : 무제한', '데이터 : 5GB(day) + 5Mbps']
        ]
      ],
      'YEOYOU': [
        [29999,
          ['YEOYOU-LG (￦24,200) 300MB+', 24200, '음성 300분, 문자 300건', '데이터 : 300MB + 400Kbps'],
          ['YEOYOU-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps']
        ],
        [30000,
          ['YEOYOU-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['YEOYOU-LG (￦33,000) 10GB', 33000, '음성 100분, 문자 100건', '데이터 : 10GB'],
          ['YEOYOU-LG (￦39,000) 15GB+', 39000, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps']
        ],
        [40000,
          ['YEOYOU-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 300MB + 3Mbps']
        ],
        [50000,
          ['YEOYOU-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['YEOYOU-LG (￦55,000) 11GB+', 55000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 3Mbps'],
          ['YEOYOU-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['YEOYOU-LG (￦66,000) 5GB+', 66000, '음성 & 문자 : 무제한', '데이터 : 5GB(day) + 5Mbps']
        ]
      ],
      'OneTelecom': [
        [29999,
          ['OneTelecom-LG (￦24,200) 300MB+', 24200, '음성 300분, 문자 300건', '데이터 : 300MB + 400Kbps'],
          ['OneTelecom-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps']
        ],
        [30000,
          ['OneTelecom-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['OneTelecom-LG (￦33,000) 10GB', 33000, '음성 100분, 문자 100건', '데이터 : 10GB'],
          ['OneTelecom-LG (￦39,000) 15GB+', 39000, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps']
        ],
        [40000,
          ['OneTelecom-LG (￦44,000) 3GB+', 44000, '음성 + 문자 : 무제한', '데이터 : 3GB + 3Mbps']
        ],
        [50000,
          ['OneTelecom-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps 속도제한'],
          ['OneTelecom-LG (￦55,000) 11GB+', 55000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 3Mbps 속도제한'],
          ['OneTelecom-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['OneTelecom-LG (￦66,000) 5GB+', 66000, '음성 & 문자 : 무제한', '데이터 : 5GB(day) + 5Mbps']
        ]
      ],
      'WHOM': [
        [30000,
          ['WHOM-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['WHOM-KT (￦36,000) 300MB+', 36000, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['WHOM-KT (￦37,500) 10GB+', 37500, '음성 100분, 문자 100건', '데이터 : 10GB + 400Kbps'],
          ['WHOM-KT (￦39,000) 10GB+', 39000, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps'],
        ],
        [40000,
          ['WHOM-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 300MB + 3Mbps']
        ],
        [50000,
          ['WHOM-LG (￦50,600) 11GB+', 50600, '음성 + 문자 : 무제한', '데이터 : 11GB + 3Mbps'],
          ['WHOM-LG (￦55,000) 11GB+', 55000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 3Mbps'],
          ['WHOM-KT (￦58,500) 10GB+', 58500, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
        ]
      ],
      'Dream Mobile': [
        [29999,
          ['Dream Mobile-KT (￦28,050) 6GB', 28050, '음성 100분, 문자 100건', '데이터 : 6GB']
        ],
        [30000,
          ['Dream Mobile-KT (￦36,000) 300MB+', 36000, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Dream Mobile-KT (￦33,000) 10GB', 33000, '음성 100분, 문자 100건', '데이터 : 10GB'],
          ['Dream Mobile-KT (￦39,900) 15GB+', 39900, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps'],
        ],
        [50000,
          ['Dream Mobile-KT (￦56,100) 10GB+', 56100, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
        ]
      ],
      'KTM-mobile': [
        [29999,
          ['Mmobile-KT (￦28,800) 10GB', 28800, '음성 100분, 문자 100건', '데이터 : 10GB']
        ],
        [30000,
          ['Mmobile-KT (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Mmobile-KT (￦35,000) 6GB', 35000, '음성 350분, 문자 350건', '데이터 : 6GB'],
          ['Mmobile-KT (￦39,000) 15GB+', 39000, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps'],
        ],
        [40000,
          ['Mmobile-KT (￦40,000) 6GB+', 40000, '음성 100분, 문자 100건', '데이터 : 6GB + 400Kbps'],
        ],
        [50000,
          ['Mmobile-KT (￦55,000) 10GB+', 55000, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
        ]
      ],
      'Well': [
        [30000,
          ['Well-KT (￦36,000) 300MB+', 36000, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Well-KT (￦37,500) 10GB', 37500, '음성 100분, 문자 100건', '데이터 : 10GB + 400Kbps'],
          ['Well-KT (￦39,600) 15GB+', 39600, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps'],
        ],
        [50000,
          ['Well-KT (￦58,500) 10GB+', 58500, '음성 + 문자 : 무제한', '데이터 : 10GB + 2GB(day) + 3Mbps'],
        ]
      ],
      'Niztelecom': [
        [29999,
          ['Niztelecom-KT (￦17,000) 2GB+', 17000, '데이터 : 2GB + 2GB(프로모션)', '충전가능시간: 11시 ~ 19시 (주말포함), 이후시간요청건은 익일에 처리됩니다.'],
          ['Niztelecom-KT (￦25,000) 5GB+', 25000, '데이터 : 5GB + 5GB(프로모션)', '충전가능시간: 11시 ~ 19시 (주말포함), 이후시간요청건은 익일에 처리됩니다.']
        ],
        [30000,
          ['Niztelecom-KT (￦35,000) 10GB+', 35000, '데이터 : 10GB + 10GB(프로모션)', '충전가능시간: 11시 ~ 19시 (주말포함), 이후시간요청건은 익일에 처리됩니다.']
        ],
        [40000,
          ['Niztelecom-KT (￦45,000) 20GB+', 45000, '데이터 : 20GB + 20GB(프로모션)', '충전가능시간: 11시 ~ 19시 (주말포함), 이후시간요청건은 익일에 처리됩니다.']
        ]
      ],
      'Valuecomm(LGT)': [
        [29999,
          ['Valuecomm-LG (￦24,200) 300MB+', 24200, '음성 300분, 문자 300건', '데이터 : 300MB + 400Kbps'],
          ['Valuecomm-LG (￦29,700) 300MB+', 29700, '음성 + 문자 : 무제한', '데이터 : 300MB + 1Mbps']
        ],
        [30000,
          ['Valuecomm-LG (￦36,300) 300MB+', 36300, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Valuecomm-LG (￦33,000) 10GB', 33000, '음성 100분, 문자 100건', '데이터 : 10GB'],
          ['Valuecomm-LG (￦39,000) 15GB+', 39000, '음성 100분, 문자 100건', '데이터 : 15GB + 3Mbps']
        ],
        [40000,
          ['Valuecomm-LG (￦40,700) 300MB+', 40700, '음성 + 문자 : 무제한', '데이터 : 300MB + 3Mbps'],
          ['Valuecomm-LG (￦44,000) 3GB+', 44000, '음성 + 문자 : 무제한', '데이터 : 3GB + 3Mbps']
        ],
        [50000,
          ['Valuecomm-LG (￦55,000) 11GB+', 55000, '음성 + 문자 : 무제한', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Valuecomm-LG (￦59,000) 11GB+', 59000, '음성 + 문자 : 무제한', '국제전화: 일 20분 제공', '데이터 : 11GB + 2GB(day) + 3Mbps'],
          ['Valuecomm-LG (￦66,000) 5GB+', 66000, '음성 & 문자 : 무제한', '데이터 : 5GB(day) + 5Mbps']
        ]
      ],
      'Voice(Special)': [
        [29999,
          ['￦10,900', 10900, '￦10,000 + ￦900(Ntel)', '가입하신 요금제에 상관없이 충전금액을 자유롭게 선택가능'],
          ['￦21,500', 21500, '￦20,000 + ￦1,500(Ntel)', '가입하신 요금제에 상관없이 충전금액을 자유롭게 선택가능']
        ],
        [30000,
          ['￦31,900', 31900, '￦30,000 + ￦1,900(Ntel)', '가입하신 요금제에 상관없이 충전금액을 자유롭게 선택가능']
        ],
        [50000,
          ['￦52,900', 52900, '￦50,000 + ￦2,900(Ntel)', '가입하신 요금제에 상관없이 충전금액을 자유롭게 선택가능'],
          ['￦63,300', 63300, '￦60,000 + ￦3,300(Ntel)', '가입하신 요금제에 상관없이 충전금액을 자유롭게 선택가능']
        ]
      ],
    }
  },
  mutations: {
    setPhoneNum(state, num){
      state.phone = num
    },
    setEggNum(state, num){
      state.egg = num
    },
    setEgg_cost(state, num){
      state.egg_cost = num
    },
    setPrepay_type(state, type){
      state.prepay_type = type
    },
    setPrepay_cost(state, str){
      if(str == ''){
        state.prepay_name = ''
        state.prepay_cost = null
        return
      }
      let name = str
      let cost
      try {
        cost = name.split('￦')[1].replace(',','').replace(')','')
      } catch (error) {
        cost = null
      }
      state.prepay_name = name
      state.prepay_cost = cost
    },
    setMonthly_cost(state, cost){
      state.monthly_cost = cost
    },
    setMonthly_name(state, str){
      state.monthly_name = str
    }
  },
  actions: {
  },
  modules: {
  }
})
