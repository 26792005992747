<template>
  <div class="home">
    <navMenu/>
    <div class='title'>{{ $t("page.일반선불.충전번호") }}</div>
    <phoneInput/>
    <div class='title'>{{ $t("page.일반선불.충전상품") }}</div>
    <chargeTypeButton/>
    <prepayButton/>
  </div>
</template>

<script>
// @ is an alias to /src
import navMenu from '@/components/navMenu.vue'
import phoneInput from '@/components/phone_input.vue'
import chargeTypeButton from '@/components/chargeTypeButton.vue'
import prepayButton from '@/components/prepayButton.vue'

export default {
  name: 'Home',
  components: {
    navMenu,
    phoneInput,
    chargeTypeButton,
    prepayButton
  },
  mounted(){
    this.$store.commit('setMonthly_cost', null)
    this.$store.commit('setMonthly_name', '')
    this.$store.commit('setPhoneNum', '')

    this.$store.commit('setEggNum', '')
    this.$store.commit('setEgg_cost', null)
  }
}
</script>

<style scoped>
.title {
  text-align: left;
  padding-left: 1rem;
  margin-top: 1rem;
}
</style>