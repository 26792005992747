<template>
  <div class="fixed-bottom deco">{{ $t('page.충전') }}</div>
</template>

<script>

export default {
  name: 'prepayButton',
  props: [
  ],
  data: function(){
    return {
    }
  },
  computed: {
  },
  mounted(){
    let btn = document.querySelector('.deco')
    let instance = this
    btn.addEventListener('click', function(){
      let date = new Date()
      let hour = date.getHours()
      let minute = date.getMinutes()

      if(hour >= 2 && hour <= 10){
          alert(instance.$i18n.t('영업시간'))
          return
      }
      if(hour == 1 && minute >= 10){
          alert(instance.$i18n.t('영업시간'))
          return
      }

      let phone = instance.$store.state.phone
      let prepay_cost = instance.$store.state.prepay_cost
      let monthly_cost = instance.$store.state.monthly_cost

      let egg = instance.$store.state.egg
      let egg_cost = instance.$store.state.egg_cost
      

      if(phone == '' && egg == ''){
        //alert('전화번호를 입력해주세요.')
        alert(instance.$i18n.t("page.월정액.번호입력"))
        return
      }
      if(prepay_cost == null && monthly_cost == null && egg_cost == null){
        //alert('요금제를 선택해주세요.')
        alert(instance.$i18n.t("page.월정액.요금제선택"))
        return
      }
      //let cost = prepay_cost == null ? monthly_cost : prepay_cost
      //alert('sucees '+phone+' '+cost)

      //alert('ขอโทษ. ฉันจะเปิดพรุ่งนี้')
      //return
      instance.$router.replace({name: 'payment'})
    })
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.deco {
  padding: 1.5rem;
  background-color: rosybrown;
  color: white
}
</style>
