<template>
  <div id="nav">
      <div class="container">
        <div class="row">
          <div class="col" id='basic'>
            <div class="menu">{{ $t("nav.일반선불") }}</div>
          </div>
          <div class="col" id='monthly'>
            <div class="menu">{{ $t("nav.월정액") }}</div>
          </div>
          <div class="col" id='egg'>
            <div class="menu">{{ $t("nav.egg") }}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'chargeTypeButton',
  components: {
  },
  mounted(){
    let instance = this
    let link = document.querySelectorAll('.row > .col')

    let url_split = window.location.href.split('/')
    let section = url_split[url_split.length-1]

    if(section != 'monthly' && section != 'egg'){
      link[0].closest('.col').classList.add('click')
    }
    else if(section == 'monthly'){
      link[1].closest('.col').classList.add('click')
    }
    else if(section == 'egg'){
      link[2].closest('.col').classList.add('click')
    }

    for (const row of link) {
      row.addEventListener('click', function(e){
        let col = e.target.closest('.col')
        for (const r of link) {
          r.classList.remove('click')
        }
        col.classList.add('click')

        if(col.id == 'basic'){
          instance.$router.push('/')
        }
        else if(col.id == 'monthly'){
          instance.$router.push('/monthly')
        }
        else if(col.id == 'egg'){
          instance.$router.push('/egg')
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#nav {
  padding: 0.2rem;
}

.col {
  padding: 0px;
  display: flex;
  font-size: 0.95rem;
  align-items: center;
  justify-content: center;
}
.click {
  background-color: #6c757d;
}
.click div {
  font-weight: bold;
  color: white;
  text-decoration: none;
}
.menu {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}
</style>
