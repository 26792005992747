<template>
  <div class="container">
    <div class='title'>{{title}}</div>
    <div class="stack" hidden></div>
    <select class="custom-select planSelect">{{selectHTMLList}}</select>
  </div>
</template>

<script>

export default {
  name: 'payPlanSelect',
  props: [
    'selectList', 'title'
  ],
  data: function(){
    return {
      selectValue: null
    }
  },
  computed: {
    selectHTMLList: function(){
      return this.createSelect(this.selectList)
    }
  },
  mounted(){
    let select = document.querySelector('.planSelect')
    let instance = this

    this.createSelect()

    select.addEventListener('change', function(event){
      instance.selectValue = event.target.value
      instance.$store.commit('setPrepay_cost', instance.selectValue)
    })
  },
  methods: {
    createSelect(autoFlag = null){
      // eslint-disable-next-line
      console.log(autoFlag)
      if(document.querySelector('.planSelect') == null){
        return ''
      }
      if(this.selectList == null){
        return ''
      }
      let options = document.querySelectorAll('option')
      if(options.length > 0 && document.querySelector('.stack').innerText == this.$store.state.prepay_type){
        return ''
      }

      document.querySelector('.stack').innerText = this.$store.state.prepay_type
      let str = ''
      let first = null
      for (const row of this.selectList) {
        if(first == null && this.title == this.$i18n.t('page.충전금액')){
          first = row.replace(/ /g, ' ')
        }
        if(first == null && this.title == this.$i18n.t('page.월정액.충전가능통신사')){
          first = row
        }
        str += `<option value=${row.replace(/ /g,'_')}>${row.replace('통신사 모름', this.$i18n.t('page.월정액.통신사모름'))}</option>`
      }
      if(this.$store.state.prepay_cost == null && this.title == this.$i18n.t('page.충전금액')){
        this.$store.commit('setPrepay_cost', first)
      }
      document.querySelector('.planSelect').innerHTML = str
      return ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.button_item {
  display: flex;
  align-items: center;
}
.button_item div:nth-child(2) {
  line-height: normal;
  padding-left: 0.3rem;
}
.present {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  margin: 0.5rem 1rem;
}
.title {
  text-align: left;
  margin-top: 1rem;
}
</style>
