<template>
  <div class="chargeButton">
    <div class='button_container'>
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-secondary active">
          <input type="radio" name="voice" id="voice" checked>
          <div class="button_item">
            <div>
              <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-telephone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"/>
              </svg>
            </div>
            <div>Voice<br>(Regular)</div>
          </div>
        </label>
        <label class="btn btn-secondary">
          <input type="radio" name="data" id="data">
          <div class="button_item">
            <div>
              <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-wifi" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z"/>
                <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"/>
              </svg>
            </div>
            <div>Data Only</div>
          </div>
        </label>
      </div>
    </div>
    <div class="present"></div>
    <payPlanSelect v-bind:selectList='selected' v-bind:title="$t('page.충전금액')"/>
    <displayCost />
  </div>
</template>

<script>
import payPlanSelect from '@/components/payPlanSelect.vue'
import displayCost from '@/components/displayCost.vue'

export default {
  name: 'chargeTypeButton',
  components: {
    payPlanSelect,
    displayCost
  },
  props: {
  },
  data: function(){
    return {
      selected: null,
      voiceSelect: [
        '￦10,000',
        '￦20,000',
        '￦30,000',
        '￦50,000'
      ],
      dataSelect: [
        'Mobing(SKT/KT) 5GB (￦30,000)',
        'Mobing(SKT/KT) 2GB (￦19,000)',
        'Mobing(SKT/KT) 1GB (￦13,500)',
        'Mobing(SKT/KT) 500M (￦9,000)',
        'SK 7mobile 5GB (￦30,000)',
        'SK 7mobile 2GB (￦19,000)',
        'SK 7mobile 1GB (￦13,500)',
        'SK 7mobile 500M (￦9,000)',
        'Eyes(SKT) 5GB (￦30,000)',
        'Eyes(SKT) 2GB (￦19,000)',
        'Eyes(SKT) 1GB (￦13,500)',
        'Eyes(SKT) 500M (￦9,000)',
        'Eyagi(SKT) 5GB (￦30,000)',
        'Eyagi(SKT) 2GB (￦19,000)',
        'Eyagi(SKT) 1GB (￦13,500)',
        'Eyagi(SKT) 500M (￦9,000)',
        'Well 2GB (￦22,000)',
        'Well 1GB (￦16,500)',
        'Well 500M (￦11,000)',
        'Well(LTE) 5GB (￦45,000)',
        'Well(LTE) 3GB (￦30,000)',
        'Snowman 1GB (￦13,500)',
        'EG 5GB (￦30,000)',
        'EG 2GB (￦19,000)',
        'EG 1GB (￦13,500)',
        'EG 500M (￦9,000)',
        'SMARTEL 5GB (￦30,000)',
        'SMARTEL 2GB (￦19,000)',
        'SMARTEL 1GB (￦13,500)',
        'SMARTEL 500M (￦9,000)',
      ]
    }
  },
  mounted(){
    let radio = document.querySelectorAll('input[type=radio]')
    let instance = this

    this.$store.commit('setPrepay_type', 'voice')

    for (const row of radio) {
      if(row.checked == true){
        this.presentType(row.name)
      }
      row.addEventListener('click', function(event){
        if(event.target.checked == true){
          instance.$store.commit('setPrepay_cost', '')
          instance.presentType(row.name)
        }
      })
    }
  },
  methods: {
    presentType(type){
      if(type == 'voice'){
        document.querySelector('.present').innerText = this.$i18n.t('page.일반선불상품.음성')//'Voice(기본음성충전)'
        this.selected = this.voiceSelect
        this.$store.commit('setPrepay_type', 'voice')
        return
      }
      if(type == 'data'){
        document.querySelector('.present').innerText = this.$i18n.t('page.일반선불상품.데이터')//'Data Only(데이터 전용 충전)'
        this.selected = this.dataSelect
        this.$store.commit('setPrepay_type', 'data')
        return
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.chargeButton{
  
}
.button_container {
  text-align: left;
  padding-left: 1rem;
}
.button_item {
  display: flex;
  align-items: center;
}
.button_item div:nth-child(2) {
  line-height: normal;
  padding-left: 0.3rem;
}
.present {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  margin: 0.5rem 1rem;
}
</style>
