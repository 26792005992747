window.onload = function() {
    const useNodeJS = true;   // if you are not using a node server, set this value to false
    const defaultLiffId = "";   // change the default LIFF value if you are not using a node server

    // DO NOT CHANGE THIS
    let myLiffId = "";

    // if node is used, fetch the environment variable and pass it to the LIFF method
    // otherwise, pass defaultLiffId
    if (useNodeJS) {
        fetch('/send-id')
            .then(function(reqResponse) {
                return reqResponse.json();
            })
            .then(function(jsonResponse) {
                myLiffId = jsonResponse.id;
                initializeLiffOrDie(myLiffId);
            })
            .catch(function(error) {
                alert(error)
            });
    } else {
        myLiffId = defaultLiffId;
        initializeLiffOrDie(myLiffId);
    }
};
function initial_liff(){
    const useNodeJS = true;   // if you are not using a node server, set this value to false
    if (useNodeJS) {
        fetch('/send-id')
            .then(function(reqResponse) {
                return reqResponse.json();
            })
            .then(function(jsonResponse) {
                myLiffId = jsonResponse.id;
                initializeLiffOrDie(myLiffId);
            })
            .catch(function(error) {
                alert(error)
            });
    } else {
        myLiffId = defaultLiffId;
        initializeLiffOrDie(myLiffId);
    }
}

/**
* Check if myLiffId is null. If null do not initiate liff.
* @param {string} myLiffId The LIFF ID of the selected element
*/
function initializeLiffOrDie(myLiffId) {
    if (!myLiffId) {
        console.log('liff not initialize!')
    } else {
        initializeLiff(myLiffId);
    }
}

/**
* Initialize LIFF
* @param {string} myLiffId The LIFF ID of the selected element
*/
function initializeLiff(myLiffId) {
    liff
        .init({
            liffId: myLiffId
        })
        .then(() => {
            // start to use LIFF's api
            initializeApp();
        })
        .catch((err) => {
            console.log(err)
        });
}

/**
 * Initialize the app by calling functions handling individual app components
 */
function initializeApp() {

    // check if the user is logged in/out, and disable inappropriate button
    if (liff.isLoggedIn()) {
        //register();
        console.log('login!')
        //alert('login!')
    } else {
        console.log('not login!')
    }
}

function register(){
    // get id token
    liff.getProfile().then(function(profile) {

        if (storageAvailable('localStorage')) {
            let storage = window['localStorage'];

            if(storage.getItem('user_info') == null){
                storage.setItem('user_info', JSON.stringify(profile))
            }
        }
        else{

        }
    }).catch(function(error) {
        window.alert('Error getting profile: ' + error);
    });
}
module.exports.register = register;

function send_order_message(msg_str, msg_notice){
        liff.sendMessages([{
            'type': 'text',
            'text': msg_str
        }]).then(() => {
            liff.sendMessages([{
                'type': 'text',
                'text': msg_notice
            }]).then(() => {
                console.log('message sent');
                liff.closeWindow();
            })
        })
        .catch((err) => {
            alert(err)
            console.log('error', err);
        });
}
module.exports.send_order_message = send_order_message;

function get_liff(){
    return liff
}
module.exports.get_liff = get_liff;