<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://static.line-scdn.net/liff/edge/2.1/sdk.js')
    document.head.appendChild(recaptchaScript)
  }
  /*mounted(){
    let instance = this
    let link = document.querySelectorAll('.row > .col')

    let url_split = window.location.href.split('/')
    let section = url_split[url_split.length-1]

    if(section == ''){
      link[0].closest('.col').classList.add('click')
    }
    else if(section == 'monthly'){
      link[1].closest('.col').classList.add('click')
    }
    else{
      link[0].closest('.col').classList.add('click')
    }

    for (const row of link) {
      row.addEventListener('click', function(e){
        let col = e.target.closest('.col')
        for (const r of link) {
          r.classList.remove('click')
        }
        col.classList.add('click')

        if(col.id == 'basic'){
          instance.$router.push('/')
        }
        else if(col.id == 'monthly'){
          instance.$router.push('/monthly')
        }
      })
    }
  }*/
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
