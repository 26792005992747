<template>
  <div class="container">
    <div class="selectcost">
      <div class='labeling'>Amount</div>
      <div class='num'>{{calculateCost}}</div>
    </div>
    <div class="serviceFee">
      <div class='labeling'>Service</div>
      <div class='num'><del>{{serviceFee_discount}}</del> {{serviceFee}}</div>
    </div>
    <div class="totalCost">
      <div class='labeling'>{{ $t('page.total') }}</div>
      <div class='num'>{{total}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'displayCost',
  props: [
  ],
  data: function(){
    return {
      selectValue: null
    }
  },
  computed: {
    calculateCost: function(){
      if(this.$store.state.prepay_cost != null){
        return '￦ '+parseInt(this.$store.state.prepay_cost).toLocaleString()
      }
      if(this.$store.state.monthly_cost != null){
        return '￦ '+parseInt(this.$store.state.monthly_cost).toLocaleString()
      }
      if(this.$store.state.egg_cost != null){
        return '￦ '+parseInt(this.$store.state.egg_cost).toLocaleString()
      }
      return ''
    },
    serviceFee_discount: function(){
      if(this.$store.state.prepay_cost != null){
        return '￦ '+parseInt(this.$store.state.service_fee_discount).toLocaleString()
      }
      if(this.$store.state.monthly_cost != null){
        return '￦ '+parseInt(this.$store.state.service_fee_discount).toLocaleString()
      }
      if(this.$store.state.egg_cost != null){
        return '￦ '+parseInt(this.$store.state.service_fee_discount).toLocaleString()
      }
      return ''
    },
    serviceFee: function(){
      if(this.$store.state.prepay_cost != null){
        return '→ ￦ '+parseInt(this.$store.state.service_fee).toLocaleString()
      }
      if(this.$store.state.monthly_cost != null){
        return '→ ￦ '+parseInt(this.$store.state.service_fee).toLocaleString()
      }
      if(this.$store.state.egg_cost != null){
        return '→ ￦ '+parseInt(this.$store.state.service_fee).toLocaleString()
      }
      return ''
    },
    total: function(){
      if(this.$store.state.prepay_cost != null){
        return '￦ '+(parseInt(this.$store.state.service_fee)+parseInt(this.$store.state.prepay_cost)).toLocaleString()
      }
      if(this.$store.state.monthly_cost != null){
        return '￦ '+(parseInt(this.$store.state.service_fee)+parseInt(this.$store.state.monthly_cost)).toLocaleString()
      }
      if(this.$store.state.egg_cost != null){
        return '￦ '+(parseInt(this.$store.state.service_fee)+parseInt(this.$store.state.egg_cost)).toLocaleString()
      }
      return ''
    }
  },
  mounted(){
    
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.container {
  margin-top: 0.4rem;
  padding: 1rem;
  background-color: rgba(200,200,200,0.5);
  padding-bottom: 5.5rem;
}
.selectcost, .serviceFee, .totalCost {
  min-height: 4rem;
  background: white;
  display: grid;
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.5rem;
}
.labeling {
  font-size: 0.8rem;
}
.num {
  font-size: 1.5rem;
  font-weight: bold;
}
del {
  color: red;
}
</style>
